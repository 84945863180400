* {
  box-sizing: border-box;
}
.app_container {
  width: 100%;
  height: 100vh;
  background-color: white;
}
.app_container_header {
  display: block;
}
.app_container_content {
  width: 100%;
  height: 70%;
  position: relative;
}
.app_container_footer {
  width: 100%;
  height: 20%;
  position: fixed;
  bottom: 0;
}

.navbar Link {
  display: block;
}

.navbar {
  width: 100%;
  height: auto;
  background-color: #555;
  display: flex;
  flex-direction: column;
}

/* Navbar links */
.nav-item {
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none;

  align-self: center;
  color: white;
  padding: 10px;
}

/* Navbar links on mouse-over */
.navbar a:hover {
  background-color: #000;
}

/* Current/active navbar link */
.active {
  background-color: #4caf50;
}

.app_responsive_overflow
{
  /* overflow-y: scroll; */
}

/* Add responsiveness - will automatically display the navbar vertically instead of horizontally on screens less than 500 pixels */
@media screen and (min-width: 701px) {
  /* Style the navigation bar */
  .navbar {
    width: 100%;
    height: 50px;
    background-color: #555;
    display: flex;
    flex-direction: row;
  }

  /* Navbar links */
  .nav-item {
    height: 100%;
    width: auto;
    text-align: center;
    text-decoration: none;

    align-self: center;
    color: white;
    padding: 10px;
  }

  .navbar Link {
    display: block;
  }

.app_responsive_overflow
{
  overflow-y: scroll;
}
}
.app_comp_loader {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* border: 16px solid #f3f3f3; */
  border-radius: 50%;
  border-top: 10px solid blue;
  border-right: 10px solid green;
  border-bottom: 10px solid red;
  border-left: 10px solid pink;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
