*{
    box-sizing: border-box;
}
.app_container
{
    width: 100%;
    height: 100vh;
    background-color: white;
}
.app_container_header
{
    display: none;
}
.app_container_content
{
    width: 100%;
    height: 60%;
    position: relative;
}
.app_container_footer
{
    width: 100%;
    height: 40%;
    position: fixed;
    bottom: 0;
}