* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.app_container {
  width: 100%;
  height: 100vh;
  background-color: green;
}

.app_container_header {
  width: 100%;
  height: 10%;
}
.app_container_content {
  width: 100%;
  height: 80%;
}
.app_container_footer {
  width: 100%;
  height: 10%;
  position: fixed;
  bottom: 0;
}
.app_cover_image {
  width: 100%;
  height: 100%;
}
.app_medium_icon {
  width: 100px;
  height: 100px;
}
.app_small_icon {
  width: 50px;
  height: 50px;
}
.app_mid_small_icon
{
  width: 30px;
  height: 30px;
}
.app_smaller_icon {
  width: 20px;
  height: 20px;
}

.app_extra_smaller_icon {
  width: 10px;
  height: 10px;
}

.app_row_flex {
  display: flex;
  flex-direction: row;
}
.app_row_flex_justify_content_center {
  justify-content: center;
}
.app_row_flex_item {
  align-self: center;
}
.app_flex_item_1 {
  flex: 1;
}

.app_column_flex {
  display: flex;
  flex-direction: column;
}

.app_align_self_center {
  align-self: center;
}

.app_justify_content_center {
  justify-content: center;
}

.app_margin_default_left {
  margin-left: 10px;
}
.app_margin_top_10 {
  margin-top: 10px;
}
.app_margin_top_5 {
  margin-top: 5px;
}
.app_margin_default_top {
  margin-top: 50px;
}

.app_larger_text {
  font-size: larger;
}

.app_large_text {
  font-size: large;
}

.app_medium_text {
  font-size: medium;
}

.app_small_text {
  font-size: small;
}
.app_smaller_text {
  font-size: x-small;
}
.app_text_bold {
  font-weight: bold;
}

.app_items_top_margin {
  margin-top: 10px;
}

.app_items_left_margin {
  margin-left: 10px;
}

.app_items_right_margin {
  margin-right: 10px;
}
.app_items_bottom_margin {
  margin-bottom: 10px;
}

.app_text_input_field {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s all;
}
.app_text_input_field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.app_button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.app_text_white {
  color: white;
}

.app_item_hide {
  display: none;
}

.app_item_visible {
  display: block;
}

.app_contents_disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.app_division {
  height: 100%;
}

.app_division_center_with_absolute {
  width: 200;
  height: 200;
}

.app_division_with_absolute {
  width: 200;
  height: 200;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

div[class^="col"] {
  /* border: 1px solid seagreen; */
}

.col-sm-1 {
  width: 8.33%;
}

.col-sm-2 {
  width: 16.66%;
}

.col-sm-3 {
  width: 25%;
}

.col-sm-4 {
  width: 33.33%;
}

.col-sm-5 {
  width: 41.66%;
}

.col-sm-6 {
  width: 50%;
}

.col-sm-7 {
  width: 58.33%;
}

.col-sm-8 {
  width: 66.66%;
}

.col-sm-9 {
  width: 75%;
}

.col-sm-10 {
  width: 83.33%;
}

.col-sm-11 {
  width: 91.66%;
}

.col-sm-12 {
  width: 100%;
}

@media only screen and (min-width: 400px) and (max-width: 700px) {
  .col-md-1 {
    width: 8.33%;
  }

  .col-md-2 {
    width: 16.66%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33%;
  }

  .col-md-5 {
    width: 41.66%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33%;
  }

  .col-md-8 {
    width: 66.66%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33%;
  }

  .col-md-11 {
    width: 91.66%;
  }

  .col-md-12 {
    width: 100%;
  }

  .app_division {
    height: auto;
  }

  .app_division_center_with_absolute {
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200px;
    height: 200px;
  }
  .app_division_with_absolute {
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200;
    height: 200;
  }
  .reverse_row {
    flex-direction: row-reverse;
  }
}

@media only screen and (min-width: 701px) {
  .col-lg-1 {
    width: 8.33%;
  }

  .col-lg-2 {
    width: 16.66%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.33%;
  }

  .col-lg-5 {
    width: 41.66%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.33%;
  }

  .col-lg-8 {
    width: 66.66%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.33%;
  }

  .col-lg-11 {
    width: 91.66%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .app_division {
    height: 100%;
  }

  .app_division_center_with_absolute {
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 200px;
    height: 200px;
  }
  .app_division_with_absolute {
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 400px;
    height: 200px;
  }
  .reverse_row {
    flex-direction: row-reverse;
  }
}

.app_loader {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  /* border: 16px solid #f3f3f3; */
  border-radius: 50%;
  border-top: 10px solid blue;
  border-right: 10px solid green;
  border-bottom: 10px solid red;
  border-left: 10px solid pink;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.app_alert {
  padding: 5px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5px;

  color: white;
}

.app_alert_closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.app_alert_content {
  text-align: center;
  display: flex;
  justify-content: center;
}

.app_alert_closebtn:hover {
  color: black;
}

.red-background-color {
  background-color: red;
}

.green-background-color {
  background-color: green;
}

.yellow-background-color {
  background-color: yellow;
}

.blue-background-color {
  background-color: blue;
}

.pink-background-color {
  background-color: pink;
}

.orange-background-color {
  background-color: orange;
}

.litegrey-background-color {
  background-color: lightslategray;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.card-container {
  padding: 20px 25px;
}
